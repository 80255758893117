'use strict';

const base = require('base:product/base');
const attributesLogic = require('./attributes');
var stockInStoreHelper = require('../stockinstore');

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    var pid;

    if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
    } else if ($('.product-set-detail').length || $('.product-set').length) {
        pid = $($el).closest('.product-detail').find('.product-id').text();
    } else {
        pid = $('.product-detail:not(".bundle-item")').data('pid');
    }

    return pid;
}

/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
function getQuantitySelector($el) {
    var quantitySelected;
    if ($el && $('.set-items').length) {
        quantitySelected = $($el).closest('.product-detail').find('.quantity-select');
    } else if ($el && $('.product-bundle').length) {
        var quantitySelectedModal = $($el).closest('.modal-footer').find('.quantity-select');
        var quantitySelectedPDP = $($el).closest('.bundle-footer').find('.quantity-select');
        if (quantitySelectedModal.val() === undefined) {
            quantitySelected = quantitySelectedPDP;
        } else {
            quantitySelected = quantitySelectedModal;
        }
    } else {
        quantitySelected = $('.quantity-select');
    }
    return quantitySelected;
}

/**
 * Retrieves the value associated with the Quantity pull-down menu
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {string} - value found in the quantity input
 */
function getQuantitySelected($el) {
    return getQuantitySelector($el).val();
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl() {
    return $('.add-to-cart-url').val();
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.choice-of-bonus-product');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $('.modal-body').spinner().start();

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }
    var bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="chooseBonusProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog choose-bonus-product-dialog" '
        + 'data-total-qty="' + data.maxBonusItems + '"'
        + 'data-UUID="' + data.uuid + '"'
        + 'data-pliUUID="' + data.pliUUID + '"'
        + 'data-addToCartUrl="' + data.addToCartUrl + '"'
        + 'data-pageStart="0"'
        + 'data-pageSize="' + data.pageSize + '"'
        + 'data-moreURL="' + data.showProductsUrlRuleBased + '"'
        + 'data-bonusChoiceRuleBased="' + data.bonusChoiceRuleBased + '">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <span class="">' + data.labels.selectprods + '</span>'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
    $('.modal-body').spinner().start();

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
            var parsedHtml = parseHtml(response.renderedTemplate);
            $('#chooseBonusProductModal .modal-body').empty();
            $('#chooseBonusProductModal .enter-message').text(response.enterDialogMessage);
            $('#chooseBonusProductModal .modal-header .close .sr-only').text(response.closeButtonText);
            $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
            $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
            $('#chooseBonusProductModal').modal('show');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
* Handles post cart add response
* @param {Object} response - response from post cart add
*/
function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
    // show add to cart toast
    if (response.newBonusDiscountLineItem
        && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else if ($('.wishlist-header').length === 0) {
        if ($('.quick-view-add-to-cart-messages').length === 0) {
            var $tabContent = $('.tab-content');
            if ($tabContent.length !== 0) {
                $tabContent.append('<div class="quick-view-add-to-cart-messages"></div>');
            } else if ($('.product-wrapper').length !== 0) {
                $('.product-wrapper').append('<div class="quick-view-add-to-cart-messages"></div>');
            }
        }
        if ($('.quick-view-add-to-basket-alert').length === 0) {
            $('.quick-view-add-to-cart-messages').append(
                '<div class="quick-view-add-to-basket-alert text-center" role="alert">'
                + response.message
                + '</div>'
            );
        }

        setTimeout(function () {
            $('.quick-view-add-to-cart-messages').remove();
        }, 3000);
    }
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts() {
    var childProducts = [];
    $('.bundle-item').each(function () {
        childProducts.push({
            pid: $(this).find('.product-id').text(),
            quantity: parseInt($(this).find('label.quantity').data('quantity'), 10)
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
function getOptions($productContainer) {
    var options = $productContainer
        .find('.product-option')
        .map(function () {
            var $elOption = $(this).find('.options-select');
            var urlValue = $elOption.val();
            var selectedValueId = $elOption.find('option[value="' + urlValue + '"]')
                .data('value-id');
            return {
                optionId: $(this).data('option-id'),
                selectedValueId: selectedValueId
            };
        }).toArray();

    return JSON.stringify(options);
}

/**
 * Makes a call to the server to report the event of adding an item to the cart
 *
 * @param {string | boolean} url - a string representing the end point to hit so that the event can be recorded, or false
 */
function miniCartReportingUrl(url) {
    if (url) {
        $.ajax({
            url: url,
            method: 'GET',
            success: function () {
                // reporting urls hit on the server
            },
            error: function () {
                // no reporting urls hit on the server
            }
        });
    }
}

$('body').on('product:handle-error', function (evt, data) {
    if (data.disable_addToCart) {
        $('button.add-to-cart').attr('disabled', 'disabled');
        $('button.add-to-cart-global').attr('disabled', 'disabled');
        $('.alert-incompatible-shipping-methods').removeClass('d-none').show();
        $('.alert-incompatible-shipping-methods-holder').append('<div class="alert-incompatible-shipping-methods"></div>');
        $('.alert-incompatible-shipping-methods').html($('#incompatible-shipping-methods-message-holder').html());
    }
});

/**
* Initializes events for product detail page
*/
function initEvents() {
    $(function () {
        const $body = $('body');
        $body.on('product:afterAttributeSelect', function (e, response) {
            $body.find('.product-detail').attr('data-pid', response.data.product.id);
            $body.find('#editWishlistProductModal .product-quickview').attr('data-pid', response.data.product.id);
            reloadCarousel(response);

            var $fpePopinLink = $body.find('.fpe-popin-link');
            if ($fpePopinLink.length) {
                $fpePopinLink.attr('data-ean', response.data.product.EAN);
            }
        });
        $('body').on('product:updateSelectableAttribute', function (e, response) {
            attributesLogic.updateSelectableVariationAttributes(response.data.product, response.container);
        });
    });
}

/**
* Reloads the carousel with the product images from the response
* @param {Object} response - the response object from the AJAX call
*/
function reloadCarousel(response) {
    const $carousel = $('.detail-slider-nav');

    // cache existing video if there is any
    let $productVideo = $carousel.find('.product-video');

    let videoInfo = {
        videoIndex: 1
    };

    if ($productVideo.length) {
        videoInfo.$videoEl = $productVideo.closest('span').clone();
        videoInfo.videoIndex = $productVideo.closest('.slick-slide').data('slick-index');
    }

    // delete carousel
    $carousel.each((i, el)=> {
        let $el = $(el);
        if ($el.is('.slick-initialized')) {
            $el.slick('unslick');
        }
    });

    // remove children
    $carousel.empty();

    // populate children
    const product = response.data.product;
    const images = product.images.Additional;
    for (let i = 0; i < images.length; i++) {
        let image = images[i];
        let $anchor = $('<span>');
        let $img = $('<img>');
        $img.attr({
            class: 'detail-image lazyload',
            src: 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',
            'data-src': image.url,
            alt: image.alt || product.productName,
            title: image.title || product.productName
        });
        $anchor.attr({
            class: 'product-detail-image-modal ajax-cbo',
            'data-cbo': product.modalURL
        });

        if (videoInfo.$videoEl && videoInfo.videoIndex === i) {
            $carousel.append(videoInfo.$videoEl);
        }

        $anchor.append($img);
        $carousel.append($anchor);
    }
    // create carousel
    const options = {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        focusOnSelect: true,
        infinite: false,
        arrows: true,
        responsive: [
            {
                breakpoint: 544,
                settings: {
                    arrows: false
                }
            }
        ]
    };
    $carousel.slick(options);
}

/**
 * Updates the quantity DOM elements post Ajax call
 * @param {UpdatedQuantity[]} quantities -
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function updateQuantities(quantities, $productContainer) {
    if ($productContainer.parent('.bonus-product-item').length <= 0) {
        var optionsHtml = quantities.map(function (quantity) {
            var selected = quantity.selected ? ' selected ' : '';
            return '<option value="' + quantity.value + '"  data-url="' + quantity.url + '"'
                + selected + '>' + quantity.value + '</option>';
        }).join('');
        getQuantitySelector($productContainer).empty().html(optionsHtml);
    }
}

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {Object} msgs - object containing resource messages
 */
function processSwatchValues(attr, $productContainer, msgs) {
    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="'
            + attrValue.value + '"]');

        if (attrValue.selected) {
            $attrValue.addClass('selected');
            $attrValue.siblings('.selected-assistive-text').text(msgs.assistiveSelectedText);
        } else {
            $attrValue.removeClass('selected');
            $attrValue.siblings('.selected-assistive-text').empty();
        }

        if (attrValue.url) {
            $attrValue.attr('data-url', attrValue.url);
            $attrValue.attr('value', attrValue.url);
        } else {
            $attrValue.removeAttr('data-url');
            $attrValue.removeAttr('value');
        }

        // Disable if not selectable
        $attrValue.removeClass('selectable unselectable');

        $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable');
    });
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function processNonSwatchValues(attr, $productContainer) {
    let $attr = '[data-attr="' + attr.id + '"]';
    let $defaultOption = $productContainer.find($attr + ' .select-' + attr.id + ' option:first');
    let $selectedBisEnabledAttribute = null;

    $defaultOption.attr('value', attr.resetUrl);

    attr.values.forEach(function (attrValue) {
        let $attrValue = $productContainer
            .find($attr + ' [data-attr-value="' + attrValue.value + '"]');
        $attrValue.attr('value', attrValue.url)
            .removeAttr('disabled');

        if (!attrValue.selectable) {
            $attrValue.attr('disabled', true);
        }

        // scope out selected attribute if back in stock icon is needed on quickview tab
        if (attrValue.selected && !attrValue.available) {
            $selectedBisEnabledAttribute = $attrValue;
        }

        // handle bis icon
        $attrValue.closest('.circle-attribute').find('.attr-bis-icon').toggleClass('d-none', !(attrValue.selectable && !attrValue.available));
    });

    // handle bis icon on quickview tabs
    let $attributesArea = $productContainer.find('.attributes-area div[data-attr="orc_size"]');

    if ($productContainer.hasClass('product-quickview') && $selectedBisEnabledAttribute && $selectedBisEnabledAttribute.length) {
        $attributesArea.find('.attr-bis-icon').toggleClass('d-none', false);
    } else {
        $attributesArea.find('.attr-bis-icon').toggleClass('d-none', true);
    }
}

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {Object} attrs - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {Object} msgs - object containing resource messages
 */
function updateAttrs(attrs, $productContainer, msgs) {
    // Currently, the only attribute type that has image swatches is Color.
    var attrsWithSwatches = ['orc_color'];

    attrs.forEach(function (attr) {
        if (attrsWithSwatches.indexOf(attr.id) > -1) {
            processSwatchValues(attr, $productContainer, msgs);
        } else {
            processNonSwatchValues(attr, $productContainer);
        }
    });
}

/**
 * Dynamically creates Bootstrap carousel from response containing images
 * @param {Object[]} imgs - Array of large product images,along with related information
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function createCarousel(imgs, $productContainer) {
    var carousel = $productContainer.find('.carousel');
    $(carousel).carousel('dispose');
    var carouselId = $(carousel).attr('id');
    $(carousel).empty().append('<ol class="carousel-indicators"></ol><div class="carousel-inner" role="listbox"></div><a class="carousel-control-prev" href="#' + carouselId + '" role="button" data-slide="prev"><span class="fa icon-prev" aria-hidden="true"></span><span class="sr-only">' + $(carousel).data('prev') + '</span></a><a class="carousel-control-next" href="#' + carouselId + '" role="button" data-slide="next"><span class="fa icon-next" aria-hidden="true"></span><span class="sr-only">' + $(carousel).data('next') + '</span></a>');
    for (var i = 0; i < imgs.length; i++) {
        $('<div class="carousel-item"><img src="' + imgs[i].url + '" class="d-block img-fluid" alt="' + imgs[i].alt + ' image number ' + parseInt(imgs[i].index, 10) + '" title="' + imgs[i].title + '" itemprop="image" /></div>').appendTo($(carousel).find('.carousel-inner'));
        $('<li data-target="#' + carouselId + '" data-slide-to="' + i + '" class=""></li>').appendTo($(carousel).find('.carousel-indicators'));
    }
    $($(carousel).find('.carousel-item')).first().addClass('active');
    $($(carousel).find('.carousel-indicators > li')).first().addClass('active');
    if (imgs.length === 1) {
        $($(carousel).find('.carousel-indicators, a[class^="carousel-control-"]')).detach();
    }
    $(carousel).carousel();
    $($(carousel).find('.carousel-indicators')).attr('aria-hidden', true);
}

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAvailability(response, $productContainer) {
    var availabilityValue = '';
    var availabilityMessages = response.product.availability.messages;
    if (!response.product.readyToOrder) {
        availabilityValue = '<li><div>' + response.resources.info_selectforstock + '</div></li>';
    } else {
        availabilityMessages.forEach(function (message) {
            availabilityValue += '<li><div>' + message + '</div></li>';
        });
    }

    $($productContainer).trigger('product:updateAvailability', {
        product: response.product,
        $productContainer: $productContainer,
        message: availabilityValue,
        resources: response.resources
    });
}

/**
 * Generates html for product attributes section
 *
 * @param {array} attributes - list of attributes
 * @return {string} - Compiled HTML
 */
function getAttributesHtml(attributes) {
    if (!attributes) {
        return '';
    }

    var html = '';

    attributes.forEach(function (attributeGroup) {
        if (attributeGroup.ID === 'mainAttributes') {
            attributeGroup.attributes.forEach(function (attribute) {
                html += '<div class="attribute-values">' + attribute.label + ': '
                    + attribute.value + '</div>';
            });
        }
    });

    return html;
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function handleVariantResponse(response, $productContainer) {
    var isChoiceOfBonusProducts = $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVaraint;
    if (response.product.variationAttributes) {
        updateAttrs(response.product.variationAttributes, $productContainer, response.resources);
        isVaraint = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVaraint) {
            $productContainer.parent('.bonus-product-item')
                .data('pid', response.product.id);

            $productContainer.parent('.bonus-product-item')
                .data('ready-to-order', response.product.readyToOrder);
        }
    }

    // Update primary images
    var primaryImageUrls = response.product.images.large;
    createCarousel(primaryImageUrls, $productContainer);

    // Update pricing
    if (!isChoiceOfBonusProducts) {
        var $priceSelector = $('.prices .price', $productContainer).length
            ? $('.prices .price', $productContainer)
            : $('.prices .price');
        $priceSelector.replaceWith(response.product.price.html);
        let price = response.product.price;
        let hasStandardDiscount = price.standard && price.standard.sales && price.standard.sales.value;
        let hasClubDiscount = price.club && price.club.sales && price.club.sales.value;
        if (hasStandardDiscount || hasClubDiscount) {
            $('.product-badge.discount-label').removeClass('d-none');
        } else {
            $('.product-badge.discount-label').addClass('d-none');
        }
    }

    // Update promotions
    $productContainer.find('.promotions').empty().html(response.product.promotionsHtml);

    updateAvailability(response, $productContainer);

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product, $productContainer: $productContainer
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart:visible, button.add-to-cart-global:visible, button.update-cart-product-global:visible, button.button-cart-mailmeback:visible').trigger('product:updateAddToCart', {
            product: response.product, $productContainer: $productContainer
        }).trigger('product:statusUpdate', response.product);
    }

    // Update attributes
    $productContainer.find('.main-attributes').empty()
        .html(getAttributesHtml(response.product.attributes));
    // Update ref label
    $productContainer.find('.reference').empty()
        .html(response.product.refLabel);
}

/**
 * Updates DOM using post-option selection Ajax response
 *
 * @param {OptionSelectionResponse} optionsHtml - Ajax response optionsHtml from selecting a product option
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateOptions(optionsHtml, $productContainer) {
    // Update options
    $productContainer.find('.product-options').empty().html(optionsHtml);
}

/**
* Handles the selection of an attribute
* @param {Object} currentTarget - the selected attribute element
* @param {Object} $productContainer - the product container element
*/
function attributeSelect(currentTarget, $productContainer) {
    const selectedValueUrl = currentTarget.value;
    const className = currentTarget.className;

    if (selectedValueUrl) {
        $('body').trigger('product:beforeAttributeSelect',
            { url: selectedValueUrl, container: $productContainer });

        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function (data) {
                handleVariantResponse(data, $productContainer);
                updateOptions(data.product.optionsHtml, $productContainer);
                updateQuantities(data.product.quantities, $productContainer);
                $('body').trigger('product:afterAttributeSelect',
                    { data: data, container: $productContainer });

                if (className.indexOf('select-orc_color') > -1) {
                    $('body').trigger('product:updateSelectableAttribute',
                        { data: data, container: $('.attribute-values[data-attr="orc_size"]') });
                } if (className.indexOf('select-orc_size') > -1) {
                    $('body').trigger('product:updateSelectableAttribute',
                        { data: data, container: $('.attribute-values[data-attr="orc_color"]') });
                }

                // Update stock in store if currentStoreId is available
                if ($('#currentStoreId').length && typeof $('#currentStoreId').val() !== 'undefined') {
                    stockInStoreHelper.getStockInStore($('#currentStoreId').val(), data.product.id);
                }

                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

/**
 * Toggles the overlay by adding or removing necessary classes from the elements.
 * @param {boolean} show - If true, it adds the classes; if false, it removes them.
 */
function toggleOverlay(show) {
    $('.added-to-cart-popup').toggleClass('show', show);
    $('body').toggleClass('overlay-active', show);
    $('.modal-background').toggleClass('active', show);
}

/**
 * Initializes the added to cart modal by toggling the overlay and setting up the close button click event.
 */
function initAddedToCartModal() {
    toggleOverlay(true);

    $('.added-to-cart-popup__close').click(function () {
        toggleOverlay(false);
    });
}

module.exports = {
    ...base,
    initEvents: initEvents(),
    addToCart: function () {
        $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', function () {
            var addToCartUrl;
            var pid;
            var pidsObj;
            var setPids;
            $('body').trigger('product:beforeAddToCart', this);

            if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
                setPids = [];
                $('.product-detail').each(function () {
                    if (!$(this).hasClass('product-set-detail')) {
                        setPids.push({
                            pid: $(this).find('.product-id').text(),
                            qty: $(this).find('.quantity-select').val(),
                            options: getOptions($(this))
                        });
                    }
                });
                pidsObj = JSON.stringify(setPids);
            }

            pid = getPidValue($(this));

            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
            }

            addToCartUrl = getAddToCartUrl();

            var productGtm = $('#quickViewModal').data('productGtm') || $('.product-detail').data('productGtm');

            var form = {
                pid: pid,
                pidsObj: pidsObj,
                childProducts: getChildProducts(),
                quantity: getQuantitySelected($(this)) || 1,
                productGtm: JSON.stringify(productGtm)
            };

            if (!$('.bundle-item').length) {
                form.options = getOptions($productContainer);
            }

            $(this).trigger('updateAddToCartFormData', form);
            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    success: function (data) {
                        if (data.is_error || data.error) {
                            $('body').trigger('product:handle-error', data);
                        } else {
                            handlePostCartAdd(data);
                            $('body').trigger('product:afterAddToCart', data);

                            miniCartReportingUrl(data.reportingURL);
                            initAddedToCartModal();

                            const resultPli = data.cart.items.find(item => item.UUID === data.pliUUID);

                            if (resultPli) {
                                const { images, variationAttributes, productName } = resultPli;
                                const smallImageUrl = images.small[0].url;
                                const smallImageAlt = images.small[0].alt;

                                $('.added-to-cart-popup__product-detail .js-product-image')
                                    .attr({ src: smallImageUrl, alt: smallImageAlt });
                                $('.added-to-cart-popup__product-detail .js-product-name').empty().append(productName);

                                if (variationAttributes[0]) {
                                    const colorText = `${variationAttributes[0].displayName} : ${variationAttributes[0].displayValue}`;
                                    $('.added-to-cart-popup__product-detail .js-product-color').empty().append(colorText);
                                }

                                if (variationAttributes[1]) {
                                    const sizeText = `${variationAttributes[1].displayName} : ${variationAttributes[1].displayValue}`;
                                    $('.added-to-cart-popup__product-detail .js-product-size').empty().append(sizeText);
                                }
                            }
                        }
                        $.spinner().stop();
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }
        });
    },

    selectAttribute: function () {
        $(document).on('change', 'select[class*="select-"], .options-select', function (e) {
            e.preventDefault();

            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }
            attributeSelect(e.currentTarget, $productContainer);
        });
    }
};
