'use strict';

/**
* Resets the Quick View by hiding the given selector and other sections
* @param {string} selectorToHide - the selector to be hidden
*/
function reset(selectorToHide) {
    $('.attribute-values-section > div').hide();

    if (selectorToHide) {
        $(selectorToHide).hide();
    } else {
        $('.product-pricing-section').hide();
        $('.product-purchase-section').hide();
    }

    $('.attribute.toggle').attr('data-show', 'false');
    $('.attribute-col.selected').removeClass('selected');
}

/**
* Shows the pricing area of a product
* @param {string} selector - the selector for the pricing area
* @returns {void}
*/
function showPricing(selector) {
    if (selector) {
        $(selector).show();
    } else {
        $('.product-pricing-section').show();
        $('.product-purchase-section').show();
    }
}

/**
 * Click handler on attribute toggle button
 * @param {*} event Event object
 */
function toggleAttribute(event) {
    event.stopPropagation();

    const $target = $(event.currentTarget);
    const attrDataShow = 'data-show';
    const attrDataAttrID = 'data-attr';
    const attrDataSelectorToHide = 'data-selector-to-hide';

    // Get action
    const showAction = $target.attr(attrDataShow) === 'false';

    const nextValue = showAction ? 'true' : 'false';

    // Get Attribute
    const attributeID = $target.attr(attrDataAttrID) ? $target.attr(attrDataAttrID) : 'orc_size';

    // Get selector to show / hide
    const $variationAttributes = $target.closest('.variation-attributes');
    const selectorToHide = $variationAttributes.find('.attributes-area').attr(attrDataSelectorToHide);

    let $elmts = $variationAttributes.find(`.attribute-values-section div[${attrDataAttrID}="${attributeID}"]`);
    if ($elmts.length === 0) {
        $elmts = $(`.attribute-values-section div[${attrDataAttrID}="${attributeID}"]`);
    }

    if ($elmts.length > 0) {
        // Hide all footer blocks
        reset(selectorToHide);

        if (showAction) {
            // Show clicked attribute
            $elmts.show();
            // Update parent class
            $target.closest('.attribute-col').addClass('selected');
        } else {
            // Show Pricing
            showPricing(selectorToHide);
        }
    }

    // Update show attribute
    // first check if this is one of the choices
    //  that was clicked rather than the menu bar
    if ($target.hasClass('circle-attribute')) {
    // take out data show to avoid conversion to true and disabling of toggling and resulting arrow pointing up
        $target.removeAttr('data-show');
    // $('.attribute-col').children().attr('data-show', 'false');
    } else {
    // this is for the menu bar
        $target.attr(attrDataShow, nextValue);
    }
}

/**
 * Click handler on change value button
 * @param {*} event Event object
 */
function selectAttributeValue(event) {
    event.stopPropagation();
    // Init jquery element
    const isCompatibleShippingMethod = $('.product-detail').attr('data-is-compatible-shipping-method');
    if (isCompatibleShippingMethod && isCompatibleShippingMethod === 'false') {
        return;
    }
    const $target = $(event.currentTarget);
    const $rootContainer = $target.closest('.variation-attributes');

    if ($target.hasClass('circle-selected') === false) {
        const attrDataAttrID = 'data-attr';
        const attrDataAttrValue = 'data-attr-value';
        const attrDataAttrURL = 'value';
        const attrDataColorSuffix = 'data-color-suffix';

        // Get attribute Data
        const attributeID = $target.closest('.attribute-values').attr(attrDataAttrID);
        const attributeValue = $target.attr(attrDataAttrValue);
        const attributeColorSuffix = $target.attr(attrDataColorSuffix);
        const attributeDisplayValue = $target.find('.attribute-value').text();
        const attributeURL = $target.attr(attrDataAttrURL) === 'null' ? null : $target.attr(attrDataAttrURL);

        // Update attribute values
        const $container = $rootContainer.find(`.attribute-values[${attrDataAttrID}="${attributeID}"]`);
        const $attributesElmnts = $container.find('.circle-attribute');
        $attributesElmnts.removeClass('circle-selected');
        $attributesElmnts.addClass('circle-unselected');
        $container.find(`.circle-attribute[${attrDataAttrValue}="${attributeValue}"]`).removeClass('circle-unselected').addClass('circle-selected');

        // Update attribute select input
        const $toggleContainer = $rootContainer.find(`.attribute[${attrDataAttrID}="${attributeID}"]`);
        $toggleContainer.find('.circle-attribute').removeClass('circle-unselected').addClass('circle-selected').text(attributeDisplayValue);

        // Update text on top of the color attribute
        $rootContainer.find(`.attribute-values-section > div[${attrDataAttrID}="${attributeID}"] .value-area > span`).removeClass('placeholder').text(attributeDisplayValue);

        // Update Color
        if (attributeColorSuffix) {
            $toggleContainer.find('.circle-attribute').removeClass(function (index, className) {
                return (className.match(/(^|\s)refinement-background-\S+/g) || []).join(' ');
            }).addClass('refinement-background-' + attributeColorSuffix);
            $toggleContainer.find('.value-area > span').removeClass(function (index, className) {
                return (className.match(/(^|\s)refinement-color-\S+/g) || []).join(' ');
            });
            $toggleContainer.find('.value-area > span').html(attributeDisplayValue);
        }

        // Trigger toggleAttribute function only for mobile or quickview
        if ($rootContainer.hasClass('mobile-only') || $rootContainer.hasClass('product-quickview-attributes')) {
            toggleAttribute(event);
        }
        // Update select input
        $target.closest('.js-attribute-container').find(`.attribute[${attrDataAttrID}="${attributeID}"] select`).val(attributeURL).trigger('change');

        // Quick view handling
        var $selectedColor = $target.find('.attribute-value').html();
        var $updateColorTab = $target.attr('data-color-suffix');
        var $quickViewModal = $target.closest('.quick-view-dialog');

        if ($quickViewModal.length === 0) {
            return;
        }

        if ($(this).hasClass('is-color')) {
            // update quickview color tab value
            $quickViewModal.find('div[data-attr="orc_color"] .circle-area').empty()
                .html('<span class="circle-attribute circle-selected is-color">' + $selectedColor + '</span>');
            $quickViewModal.find('div[data-attr="orc_color"] .circle-area span').addClass('refinement-background-' + $updateColorTab);
            $quickViewModal.find('div[data-attr="orc_color"] .value-area span').html($selectedColor);
        } else {
            // update quickview size tab value
            var selectedSizeHtml = $target.find('.attribute-value').html();

            $quickViewModal.find('div[data-attr="orc_size"] .circle-area').empty()
                .html(`<span class="circle-attribute circle-selected">${selectedSizeHtml}
                        <span class="attr-bis-icon d-none">
                            <i class="fa-regular fa-envelope" aria-hidden="true"></i>
                        </span>
                    </span>`);
        }
    }
}

module.exports = {
    toggleAttribute,
    selectAttributeValue,
    updateSelectableVariationAttributes: function (product, $container) {
        let values;
        switch ($container.attr('data-attr')) {
            case 'orc_size':
                values = product.variationAttributes[1].values;
                break;
            case 'orc_color':
                values = product.variationAttributes[0].values;
                break;
            default:
                break;
        }

        $container.children().each(function () {
            let valueAssociated = values.find(value => $(this).attr('data-attr-value') === value.id);
            if (valueAssociated && valueAssociated.selectable && $(this).hasClass('circle-disabled')) {
                $(this).removeClass('circle-disabled');
            } if (valueAssociated && !valueAssociated.selectable && !$(this).hasClass('circle-disabled')) {
                $(this).addClass('circle-disabled');
            }
        });
    }
};
